//import imgHobby from '../images/plan-hobby.svg'
//import imgStarter from '../images/plan-starter.svg'
//import imgGrowth from '../images/plan-growth.svg'
//import imgUltimate from '../images/plan-enterprise.svg'
//import imgOpenSource from '../images/plan-open-source.svg'
import imgEnterprise1 from '../images/plan-enterprise1.svg'
//import imgEnterprise2 from '../images/plan-enterprise2.svg'

const plans = {
    cloud: [
        {
            title: 'Hobby',
            //image: imgHobby,
            popular: false,
            price: '$0',
            priceDetail: 'forever',
            description: 'Ideal for just yourself or small side projects',
            callToAction: 'Create free account',
            callToActionDest: {
                type: 'url',
                value: 'https://app.aiprentice.com/login',
            },
            benefits: [
                'Capture up to <b>2 projects</b> or <b> 500 views</b>',
                '<b>All core filtering features</b>',
                '<b>1</b> team member',
                '180-day data retention',
            ],
        },
        {
            title: 'Studio',
            //image: imgStarter,
            popular: false,
            price: '$320',
            priceDetail: '/month\nor\n$3500 annually',
            description: 'Ideal for small teams',
            callToAction: 'Start my free trial',
            callToActionDest: {
                type: 'url',
                value: 'https://app.aiprentice.com/login',
            },
            benefits: [
                '<span class="p-plan-benefit-lg">Everything in Hobby, plus:</span>',
                'Capture up to <b>3,000 views</b>',
                'up to <b>10</b> team members',
                //'<a href="/docs/features/feature-flags" target="blank">Feature flags</a>',
                //'<a href="/docs/features/cohorts" target="blank">User cohorts</a>',
                //'<a href="/docs/features/users#user-history" target="blank">Individual user history</a>',
                //'12-month data retention',
                'Email support',
            ],
        },
        {
            title: 'Firm',
            //image: imgGrowth,
            popular: true,
            price: '$400',
            priceDetail: '/month\nor\n$4500 annually',
            description: 'Ideal for firms',
            callToAction: 'Start my free trial',
            callToActionDest: {
                type: 'url',
                value: 'https://app.aiprentice.com/login',
            },
            benefits: [
                '<span class="p-plan-benefit-lg">Everything in Studio, plus:</span>',
                'up to <b>30</b> team members',
                'Up to <b>50,000 views</b>',
                //'Additional views charged $99/500K',
                //'12-month data retention',
                'Phone support',
            ],
        },
        {
            title: 'Enterprise',
            //image: imgUltimate,
            popular: false,
            price: 'Custom',
            priceDetail: 'contact us',
            description: 'Ideal for larger businesses and firms',
            callToAction: 'Contact sales',
            callToActionDest: {
                type: 'url',
                value: 'mailto:sales@aiprentice.com?subject=Enquiry%20about%20enterprise%20plan',
            },
            benefits: [
                '<span class="p-plan-benefit-lg">Everything in Firm, plus:</span>',
                'Capture <b>unlimited</b> views and projects',
                //'<b>Unlimited</b> data retention',
                'Customized filters',
                'Dedicated support',
                'SSO with OpenID Connect',
            ],
        },
    ],
    enterprise: [
        {
            title: 'Self host',
            image: imgEnterprise1,
            popular: false,
            price: 'Starts at $1,000',
            priceDetail: '/month',
            description:
                'Ideal for large firms needing to own the data off the cloud, but do not want to maintain the service',
            callToAction: 'Contact sales',
            callToActionDest: {
                type: 'url',
                value: 'mailto:sales@aiprentice.com?subject=Enquiry%20about%20selfhost%20%20plan',
            },
            benefits: [
                '<span class="p-plan-benefit-lg">Everything in Cloud Plan, plus:</span>',
                'Aiprentice deploys and manages everything on your own infrastructure',
                'Dedicated support',
                'SSO in your way',
                'Uptime and scalability SLAs',
            ],
        },
        /*
    {
        title: 'Supported Enterprise',
        image: imgEnterprise2,
        popular: true,
        price: 'Custom',
        priceDetail: 'contact us',
        description:
            'Ideal for companies that do not want the hassle of managing PostHog, but want to own their data.',
        callToAction: 'Contact sales',
        wraps: false,
        callToActionDest: {
            type: 'url',
            value: 'mailto:sales@posthog.com?subject=Enquiry%20about%20enterprise%20supported%20plan',
        },
        benefits: [
            '<span class="p-plan-benefit-lg">Everything in Enterprise, plus:</span>',
            'PostHog deploys and maintains everything (in your own infrastructure)',
            'Uptime and scalability SLAs',
        ],
    },*/
    ],
    'open-source': [
        {
            title: 'Open source',
            //image: imgOpenSource,
            popular: true,
            price: 'Free',
            priceDetail: '',
            description: 'Ideal for those with smaller traffic volumes, happy to manage their own infrastructure',
            callToAction: 'Deploy',
            callToActionDest: {
                type: 'url',
                value: 'https://posthog.com/docs/deployment',
            },
            benefits: [
                'Advanced analytics',
                '<a href="/docs/features/feature-flags" target="blank">Feature flags</a>',
                '<a href="/docs/features/cohorts" target="blank">User cohorts</a>',
                '<a href="/docs/features/users#user-history" target="blank">Individual user history</a>',
                'Libraries for all major languages and frameworks',
                'Integrations with Slack, Teams and Discord',
                'Community support',
            ],
        },
    ],
}
const faqs = [
    {
        q: 'What happens when I reach the maximum number of views or projects in my plan?',
        a: 'We will let you know when you are close to the limit and prompt you to upgrade to a different plan. We will not stop you from indexing projects but we might limit your ability to search all of your data until the next billing period.',
    },
    {
        q: 'Is there a free trial on paid plans?',
        a: 'You can get a 30-day free trial on our Studio and Firm plans.',
    },
    {
        q: 'What happens after the data retention period elapses?',
        a: 'On the Cloud free plans, any data stored for more than the retention period may be permanently deleted from our systems. On the Business and Enterprise plans, you control your data retention and what happens to your data afterwards.',
    },
    /*{
      q: 'Can I switch between the Cloud and Enterprise plans?',
      a:
          'We are working hard to enable a bridge that allows data transfer between cloud instances and enterprise instances. This will be possible in the coming months.',
  },*/
]

export { plans, faqs }
